<template>
    <Card title="Timeline">
        <form class="row g-3" @submit.prevent>
            <div class="col-12">
                <h3 class="float-start">{{ isEdit ? 'UPDATE' : 'NEW' }} TIMELINE</h3>
                <div class="float-end">
                    <button type="button" class="btn btn-outline-secondary btn-sm m-1" @click="back">BACK</button>
                    <button type="button" class="btn btn-outline-danger btn-sm m-1" @click="remove" v-if="isEdit">DELETE</button>
                    <button type="button" class="btn btn-outline-success btn-sm m-1" @click="handleSubmit">SAVE</button>
                </div>
                <!-- :class="{'is-invalid': !$v.newItem.title.requred && $v.newItem.title.$error}" -->
            </div>
            <div class="col-12">
                <label for="title" class="form-label">Title</label>
                <input type="text" class="form-control" id="title" v-model="newItem.title">
            </div>
            <div class="col-12">
                <label for="local" class="form-label">Local</label>
                <input type="text" class="form-control" id="local" v-model="newItem.local">
            </div>
            <div class="col-12">
                <label for="location" class="form-label">Location</label>
                <input type="text" class="form-control" id="location" v-model="newItem.location">
            </div>
            <div class="col-12">
                <label for="text" class="form-label">Text</label>
                <textarea class="form-control" id="text" rows="5" v-model="newItem.text"></textarea>
            </div>
            <div class="col-md-6">
                <label for="from" class="form-label">From Date</label>
                <input type="date" class="form-control" id="from" v-model="newItem.from">
            </div>
            <div class="col-md-6">
                <label for="to" class="form-label">To Date</label>
                <input type="date" class="form-control" id="to" v-model="newItem.to">
            </div>
        </form>
    </Card>
</template>

<script>
import firebase from '../../../firebase'
import moment from 'moment'
import { required } from 'vuelidate/lib/validators'
import Card from '@/components/admin/Card.vue'
export default {
    components: { Card },
    props: [
        'collection',
        'item'
    ],
    data() {
        return {
            newItem: {
                title: '',
                local: '',
                location: '',
                from: null,
                to: null
            },
            isEdit: false
        }
    },
    validation: {
        newItem: {
            title: required,
            local: required,
            location: required,
            from: required
        }
    },
    methods: {
        handleSubmit() {
            if(this.isEdit) {
                firebase.firestore().collection(this.collection).doc(this.item.id).set(this.newItem).then(() => this.back()).catch(err => console.error(err))
            } else {
                firebase.firestore().collection(this.collection).add(this.newItem).then(({ id }) => /* console.log(id) */ this.back()).catch(err => console.error(err))
            }
        },
        remove() {
            firebase.firestore().collection(this.collection).doc(this.item.id).delete().then(() => this.back()).catch(err => console.error(err))
        },
        back() {
            this.$router.push({ name: 'AdminTimelineList' })
        }
    },
    mounted() {
        if(this.item) {
            this.isEdit = true
            this.newItem = { ...this.item }
            delete this.newItem.id
        }
    }
}
</script>

<style scoped>
.form-control:focus {
    box-shadow: none;
}

textarea {
    resize: none;
}
</style>